import {React, useState, useEffect} from 'react';
import {Routes, Route, Link, Outlet} from 'react-router-dom';

const SideNav = () =>{

	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	//handle side bar
	const [isShow,setIsShow] = useState(
		()=>{
			if(windowDimensions.width <= 767){
			return false;
			}
			else return true;
		}
	);
	
	// get windows dimensions
	function getWindowDimensions() {
  		const { innerWidth: width, innerHeight: height } = window;
		
  		return {
    		width,
    		height
  		};

		
	}

	function useWindowDimensions() {

  		useEffect(() => {
    			function handleResize() {
      				setWindowDimensions(getWindowDimensions());
    			}

    		window.addEventListener('resize', handleResize);
    		return () => window.removeEventListener('resize', handleResize);
  		}, []);

  		return windowDimensions.width;
	}

	console.log(useWindowDimensions());
	
	const HandleSidebar = ()=>{
		setIsShow(current=> !current);
	}
	
	return(
		<>	
			{/* toggle sidebar */}
			
			<div className="mob-header">
				<button className="toggler-menu" onClick={HandleSidebar} style={{cursor: "none"}}>
				<div className="" style={{display: isShow?'none' : 'block'}}>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<div className="fs-5" style={{display: isShow?'block' : 'none'}}>
					<h1 className="text-white">&times;</h1>
				</div>
				</button>
			</div>
			<div className="flex-column flex-shrink-0 sidebar" style={{display: windowDimensions.width > 767? 'flex': isShow? 'flex':'none',transition: '0.5s'}}>
				<a href="#home" className="d-block p-3 link-dark text-center text-decoration-none logo" onClick={HandleSidebar} title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Icon-only" style={{backgroundColor: "#13294A"}}>
					{/* site logo */}
					<img src="img/9.png" data-aos="fade-right" width="40" height="50" alt="logo"/>
					{/* <label className="" style={{fontSize:"1rem"}}><strong>SHUBHANSHU</strong></label> */}
					<span className="visually-hidden">Icon-only</span>
				</a>
				<ul className="nav nav-pills nav-flush flex-column mb-auto text-center sidebar-icon">
					{/* home button */}
					<li className="nav-item" onClick={HandleSidebar}>
						<Link to="/manage-homepage" className="nav-link py-3" aria-current="page" title="Home" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" data-bs-original-title="Home">
							<i className="fa fa-home"></i>
						</Link>
					</li>
					{/* about button */}
					<li className="nav-item" onClick={HandleSidebar}>
						<Link to="/manage-Biography" href="#about" className="nav-link py-3" title="About" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="About">
							<i className="fa fa-user"></i>
						</Link>
					</li>
					{/* resume button */}
					<li onClick={HandleSidebar}>
						<a href="#resume" className="nav-link py-3" title="Resume" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Resume">
							<i className="fa fa-file-text"></i>
						</a>
					</li>
					{/* work button */}
					<li onClick={HandleSidebar}>
						<a href="#work" className="nav-link py-3" title="Work" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Work">
							<i className="fa fa-suitcase"></i>
						</a>
					</li>
					{/* blog button */}
					<li onClick={HandleSidebar}>
						<a href="#blog" className="nav-link py-3" title="Blog" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Blog">
							<i className="fas fa-blog"></i>
						</a>
					</li>
					{/* contact button */}
					<li onClick={HandleSidebar}>
						<a href="#contact" className="nav-link py-3" title="Contact" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Contact">
							<i className="fa fa-phone"></i>
						</a>
					</li>
				</ul>
				{/* <div className="dropdown border-top">
				<a href="#home" className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
					<img src="img/me.png" alt="mdo" width="24" height="24" className="rounded-circle"/>
				</a>
				<ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
					<li><a className="dropdown-item" href="#new">New project...</a></li>
					<li><a className="dropdown-item" href="#setting">Settings</a></li>
					<li><a className="dropdown-item" href="#profile">Profile</a></li>
					<li><hr className="dropdown-divider" /></li>
					<li><a className="dropdown-item" href="#signout">Sign out</a></li>
				</ul>
				</div> */}
			</div>
		</>
	)
}

export default SideNav;