import React from 'react';
import './About.css'

const About = () =>{
	return(
	<>
		<section id="about" className="section theme-light dark-bg">
			<div className="container">
				<div className="row align-items-center justify-content-center">
					<div className="col-md-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-duration="1200">
						<div className="about-me">
							<div className="img">
								<div className="img-in">
									<img src="img/me.png" alt="about"/>
								</div>
								<div className="nav social-icons justify-content-center">
									<a href="https://www.facebook.com/" rel="noreferrer" target="_blank" style={{cursor: "none"}}>
										<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
											<path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
										</svg>
									</a>
									<a href="https://twitter.com/iamsbkm" rel="noreferrer" target="_blank" style={{cursor: "none"}}>
										<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
											<path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
										</svg>
									</a>
									<a href="https://www.instagram.com/iam.sbkm" rel="noreferrer" target="_blank" style={{cursor: "none"}}>
										<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
											<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
										</svg>
									</a>
									<a href="https://www.linkedin.com/in/shubhanshumaurya" rel="noreferrer" target="_blank" style={{cursor: "none"}}>
										<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
											<path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
										</svg>
									</a>
									<a href="https://www.pinterest.com/shubhanshumaur2" rel="noreferrer" target="_blank" style={{cursor: "none"}}>
										<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
											<path d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z"></path>
										</svg>
									</a>
								</div>
							</div>
							<div className="info">
								<p>Ux/Ui Designer</p>
								<h3>Shubhanshu Maurya</h3>
							</div>
						</div>
					</div>
					<div className="col-lg-7 ml-auto aos-init" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
						<div className="about-info">
							<div className="title">
								<h3>Biography</h3>
							</div>
						<div className="about-text">
							<div>
								<span><strong className="fs-5">🇵 🇷 🇴 🇫 🇪 🇸 🇸 🇮 🇴 🇳 🇦 🇱 ᵈᵉᵗᵃⁱˡˢ</strong></span>
								<br/>
								<br/><span>✓ I am a 𝕃𝕚𝕟𝕦𝕩 enthusiast, I work on professional projects at corporate working hour, and handle the personal projects during Non-working hour .</span>
								<br/><span>✓ I love to working with 𝔾𝕚𝕥 and 𝔾𝕚𝕥𝕙𝕦𝕓, which provides additional facility to a programmer.</span>
								<br/><span>✓ I always keep 𝚎𝚡𝚙𝚎𝚛𝚒𝚖𝚎𝚗𝚝𝚒𝚗𝚐 with my machine(Laptop, PC, Smartphones etc.), whether it is hardware or software, because I always learn something new from it.</span>
								<br/><span>✓ Mostly I work on 𝕨𝕖𝕓 𝕕𝕖𝕧𝕖𝕝𝕠𝕡𝕞𝕖𝕟𝕥, because I find it very fun, The more complexity increases, the more eagerness increases in understanding those things and completing them with success.</span>
								<br/><br/>
								<strong className="fs-5"><span>🇵 🇪 🇷 🇸 🇴 🇳 🇦 🇱ᵈᵉᵗᵃⁱˡˢ</span></strong>
								<br/>
								<br/><span>✓ I always been a student, because a student has the passion to learn and do something new, and I am always ready to learn and do something new. I want to do something out of the ordinary, such an invention that advances both 𝕙𝕦𝕞𝕒𝕟𝕚𝕥𝕪 and 𝕟𝕒𝕥𝕦𝕣𝕖, no one is harmed, whether it is nature or humanity.</span>
								<br/><span>✓ The world 🌎 needs to Develop such a technology that not only takes humanity to the heights but also helps the 𝕟𝕒𝕥𝕦𝕣𝕖 touches its 𝗲𝘅𝘁𝗿𝗲𝗺𝗲 𝗹𝗶𝗺𝗶𝘁𝘀.</span>
								{/* <br/><span>✓ A world where there is greenery all around, and human beings take their potential to the extreme, taking advantage of modernity on the path of spirituality.</span> */}

							</div>
						</div>
						<div className="info-list">
							<div className="row">
								<div className="col-sm-6">
									<ul>
										<li>
											<label>Name: </label>
											<span>Shubhanshu Maurya</span>
										</li>
										<li>
											<label>Birthday: </label>
												<span>6th June 1997</span>
											</li>
										<li>
											<label>Age: </label>
											<span>24 years</span>
										</li>
										<li>
											<label>Address: </label>
											<span>Varanasi, Uttar-Pradesh</span>
										</li>
									</ul>
								</div>
								<div className="col-sm-6">
									<ul>
										<li>
											<label>Phone: </label>
											<span>(+91) 9140744685</span>
										</li>
										<li>
											<label>Email: </label>
											<span>shubhanshumaur2@gmail.com</span>
										</li>
										<li>
											<label>Skype: </label>
											<span>shubhanshumaurya</span>
										</li>
										<li>
											<label>Freelance: </label>
											<span>Available</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="separated" style={{backgroundImage: "url(img/sep1.png)", backgroundSize: "auto 40px"}}></div>
			
			<div className="title">
				<h3>What I do?</h3>
			</div>
			<div className="row">
				<div className="col-md-6 col-lg-4 my-3 aos-init" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="">
					<div className="feature-box-01">
						<div className="icon">
							<i className="fa-solid fa-tablet-screen-button"></i>
						</div>
						<div className="feature-content">
							<h5>Web Design</h5>
							<p>Web design encompasses many different skills and disciplines in the production and maintenance of websites. 
							
							</p>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-4 my-3 aos-init" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
					<div className="feature-box-01">
						<div className="icon">
							<i className="fa-solid fa-desktop"></i>
						</div>
						<div className="feature-content">
							<h5>Web Development</h5>
								<p>
								Web development is the work involved in developing a website for the Internet or an intranet. 
								
    								</p>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-4 my-3 aos-init" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="400">
					<div className="feature-box-01">
						<div className="icon">
							<i className="fa-solid fa-screwdriver-wrench"></i>
						</div>
						<div className="feature-content">
							<h5>DevOps</h5>
							<p>DevOps is a set of practices that combines software development and IT operations. 
							
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="separated" style={{backgroundImage: "url(img/sep.png)"}}></div>
				<div className="title">
					<h3>Awards.</h3>
				</div>
				<div className="row">
					<div className="col-lg-4 m-15px-tb aos-init" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="0">
						<div className="feature-box-02 d-flex align-items-center">
							<div className="icon">
								<img src="img/web-development-page.gif" alt="award"/>
							</div>
							<div className="media-body">
								<h6>Tech Marathon7 - A 16 Hours Techno Brain Race</h6>
								<label>Technical Club | April 2019</label>
								<p>Web Dev. Competition (1st Rank)</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 m-15px-tb aos-init" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
						<div className="feature-box-02 d-flex align-items-center">
							<div className="icon">
								<img src="img/web-development-banner.png" alt="award" />
							</div>
							<div className="media-body">
								<h6>WebDX - An 8 Hours Continuous Brain Storming</h6>
								<label>Technical Club | Feb 2019</label>
								<p>Web Dev. Competition (1st Rank)</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 m-15px-tb aos-init" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="400">
						<div className="feature-box-02 d-flex align-items-center">
							<div className="icon">
								<img src="img/5.png" alt="award" />
							</div>
							<div className="media-body">
								<h6>Best Learner Award (Technical Quiz)</h6>
								<label>Technical Club | Feb 2018</label>
								<p>Site of the day</p>
							</div>
						</div>
					</div>
				</div>
			<div className="separated" style={{backgroundImage: "url(img/sep.png)"}}></div>
			<div className="title">
				<h3>Testimonials.</h3>
			</div>
			<div className="testimonial-wrapper">
				<div className="slick-slider slick-initialized" dir="ltr">
					<button type="button" data-role="none" className="slick-arrow slick-prev" style={{display: "block", cursor: "none"}}> Previous</button>
						<div className="slick-list">
							<div className="slick-track" style={{width: "4544px", opacity: "1", transform: "translate3d(-1136px, 0px, 0px)"}}>
								<div data-index="-2" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "568px"}}>
									<div>
										<div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" tabIndex="-1" style={{width: "100%", display: "inline-block"}} className="aos-init">
											<div className="testimonial-01 media">
												<div className="avatar">
													<img src="img/me1.jpg" alt="review comments" />
												</div>
												<div className="media-body">
													<p> Lorem Ipsum is simply dummy text of the printing and
      														typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
													</p>
													<h6>Saurabh Mishra</h6>
													<span>CEO at EduOne</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div data-index="-1" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "568px"}}>
									<div>
										<div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400" tabIndex="-1" style={{width: "100%", display: "inline-block"}} className="aos-init">
											<div className="testimonial-01 media">
												<div className="avatar">
													<img src="img/me1.jpg" alt="review comments"/>
												</div>
												<div className="media-body">
													<p> Lorem Ipsum is simply dummy text of the printing and
      														typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
													</p>
													<h6>Ritesh</h6>
													<span>Visual Designer</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div data-index="0" className="slick-slide slick-active slick-current" tabIndex="-1" aria-hidden="false" style={{outline: "none", width: "568px"}}>
									<div>
										<div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="" tabIndex="-1" style={{width: "100%", display: "inline-block"}} className="aos-init">
											<div className="testimonial-01 media">
												<div className="avatar">
													<img src="img/prince.jpg" alt="review comments"/>
												</div>
												<div className="media-body">
													<p>  He is the CEO of ABC Company, which is an IT service Management Company. Currently it is the leading company in IT sector.
													</p>
													<h6>Priyanshu</h6>
													<span>CEO at ABC</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div data-index="1" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{outline: "none", width: "568px"}}>
									<div>
										<div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" tabIndex="-1" style={{width: "100%", display: "inline-block"}} className="aos-init">
											<div className="testimonial-01 media">
												<div className="avatar">
													<img src="img/dinesh.jpg" alt="review comments" />
												</div>
												<div className="media-body">
													<p> XYZ is the topmost company who worked on WFA (Work From AnyWhere). It is the Product Based Company.
													</p>
													<h6>Dinesh kumar</h6>
													<span>CEO at XYZ</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div data-index="2" className="slick-slide" tabIndex="-1" aria-hidden="true" style={{outline: "none", width: "568px"}}>
									<div>
										<div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400" tabIndex="-1" style={{width: "100%", display: "inline-block"}} className="aos-init">
											<div className="testimonial-01 media">
												<div className="avatar">
													<img src="img/me1.jpg" alt="review comments" />
												</div>
												<div className="media-body">
													<p> Lorem Ipsum is simply dummy text of the printing and
      														typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
													</p>
													<h6>Anveshika Maurya</h6>
													<span>Visual Designer</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div data-index="3" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "568px"}}>
									<div>
										<div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="" tabIndex="-1" style={{width: "100%", display: "inline-block"}} className="aos-init">
											<div className="testimonial-01 media">
												<div className="avatar">
													<img src="img/me1.jpg" alt="review comments" />
												</div>
												<div className="media-body">
													<p>  Lorem Ipsum is simply dummy text of the printing and
      														typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
													</p>
													<h6>Nancy Byers</h6>
													<span>CEO at NPC</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div data-index="4" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "568px"}}>
									<div>
										<div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" tabIndex="-1" style={{width: "100%", display: "inline-block"}} className="aos-init">
											<div className="testimonial-01 media">
												<div className="avatar">
													<img src="img/me1.jpg" alt="review comments" />
												</div>
												<div className="media-body">
													<p> Lorem Ipsum is simply dummy text of the printing and
      														typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
													</p>
													<h6>Jara Afsari</h6>
													<span>CEO at RT</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div data-index="5" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "568px"}}>
									<div>
										<div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400" tabIndex="-1" style={{width: "100%", display: "inline-block"}} className="aos-init">
											<div className="testimonial-01 media">
												<div className="avatar">
													<img src="img/me1.jpg" alt="review comments" />
												</div>
												<div className="media-body">
													<p> Lorem Ipsum is simply dummy text of the printing and
      														typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
													</p>
													<h6>Janiaya kiaram</h6>
													<span>Visual Designer</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<button type="button" data-role="none" className="slick-arrow slick-next" style={{display: "block", cursor: "none"}}> Next</button>
						<ul className="slick-dots" style={{display: "block"}}>
							<li className="slick-active">
								<button style={{cursor: "none"}}>1</button>
							</li>
							<li className="">
								<button style={{cursor: "none"}}>2</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	</>
	);
}

export default About;