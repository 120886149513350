import {React, useState} from 'react';
import SideNav from '../SideNav';
import EditPersonalDetails from './EditPersonalDetails';
import AddPersonalDetails from './AddPersonalDetails';
import {Editor} from '@tinymce/tinymce-react';
import EditSocialDetails from './EditSocialDetails'
import './ManageBioGraphy.css';

const ManageBiography = ()=>{


	// handle SOCIAL section
	const [isDisabled, setIsDisabled] = useState(true);
	const [inputStyle, setInputStyle] = useState({
		border: "none",
  		backgroundColor: "transparent"
	})

	 const editSocialDetails = ()=> {
	 	setIsDisabled(false);
		setInputStyle(
			{
				border: "none",
  				backgroundColor: "transparent",
  				borderBottom: "2px solid gray"
			}
		)
	 }

	 const saveSocialDetails = ()=> {
		setIsDisabled(true);
		setInputStyle(
			{
				border: "none",
  				backgroundColor: "transparent",
				borderBottom: "none"
			}
		)
	 }

	//  sync social media links section input value and store in variable
	const [socialData, setSocialData] = useState(
		{
			facebook: "",
			twitter: "",
			instagram: "",
			linkedIn: "",
			pinterest: ""
		}
	)

	let name, value;
	const postSocialData = (event)=> {
		name= event.target.name;
		value= event.target.value;
		setSocialData({...socialData, [name] : value})
	}

  // handle ABOUT section
  const [isAboutDisabled, setIsAboutDisabled] = useState(true);
	const [textEditorStyle, setTextEditorStyle] = useState({
		  border: "none",
  		backgroundColor: "transparent"
	})

	 const editAboutDetails = ()=> {
	 	setIsAboutDisabled(false);
		setTextEditorStyle(
			{
				  border: "none",
  				backgroundColor: "transparent",
			}
		)
	 }

	 const saveAboutDetails = ()=> {
		setIsAboutDisabled(true);
		setTextEditorStyle(
			{
				border: "none",
  			backgroundColor: "transparent",
			}
		)
	 }

	//  sync social media links section input value and store in variable
	const [aboutData, setAboutData] = useState(
		{
			aboutMe: "",
		}
	)

	let textEditorName, textEditorValue;
	const postAboutData = (event)=> {
		textEditorName = event.target.name;
		textEditorValue = event.target.value;
		setAboutData({...socialData, [textEditorName] : textEditorValue})
	}

return(
<>
<div className="d-flex position-relative">
<div style={{zIndex: "999"}}><SideNav/></div>
<div className="w-100">
<section style={{backgroundColor: "#eee"}}>
  <div className="container py-5">
    <div className="row">
      <div className="col">
        <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-4">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
            <li className="breadcrumb-item"><a href="#">User</a></li>
            <li className="breadcrumb-item active" aria-current="page">User Profile</li>
          </ol>
        </nav>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-4">
        <div className="card mb-4">
          <div className="card-body text-center">
            <img src="" alt="avatar"
              className="rounded-circle img-fluid" style={{width: "150px"}} />
            <h5 className="my-3">Shubhanshu</h5>
            <p className="text-muted mb-1">Full Stack Developer</p>
            <p className="text-muted mb-4">Varanasi, UP</p>
            <div className="d-flex justify-content-center mb-2">
              <button type="button" className="btn btn-primary">Follow</button>
              <button type="button" className="btn btn-outline-primary ms-1">Message</button>
            </div>
          </div>
        </div>
        <div className="card mb-4 mb-lg-4">
	        <div className="d-flex position-relative p-2">
	          <span><strong>Social Links</strong></span>
	          <div className="position-absolute" style={{right: "1rem", cursor: "pointer"}} onClick={editSocialDetails}><i className="fa fa-edit"></i></div>
	            {/* <EditSocialDetails/> */}
	        </div>
          <div className="card-body p-0">
	        <form method="POST">
            <ul className="list-group list-group-flush rounded-3">
              <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                <i className="fab fa-facebook-f fa-lg" style={{color: "#3b5998"}}></i>
                {/* <p className="mb-0">https://mdbootstrap.com</p> */}
		            <input type="text" value={socialData.facebook} onChange={postSocialData} name="facebook" disabled={isDisabled} style={inputStyle}/>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                <i className="fab fa-twitter fa-lg" style={{color: "#1d9bf0"}}></i>
                {/* <p className="mb-0">mdbootstrap</p> */}
		            <input type="text" value={socialData.twitter} onChange={postSocialData} name="twitter" disabled={isDisabled} style={inputStyle}/>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                <i className="fab fa-instagram fa-lg" style={{color: "#ac2bac"}}></i>
                {/* <p className="mb-0">@mdbootstrap</p> */}
		            <input type="text" value={socialData.instagram} onChange={postSocialData} name="instagram" disabled={isDisabled} style={inputStyle}/>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                <i className="fab fa-linkedin fa-lg" style={{color: "#0a66c2"}}></i>
                {/* <p className="mb-0">mdbootstrap</p> */}
		            <input type="text" value={socialData.linkedIn} onChange={postSocialData} name="linkedIn" disabled={isDisabled} style={inputStyle}/>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                <i className="fab fa-pinterest fa-lg" style={{color: "#c31952"}}></i>
                {/* <p className="mb-0">mdbootstrap</p> */}
		            <input type="text" value={socialData.pinterest} onChange={postSocialData} name="pinterest" disabled={isDisabled} style={inputStyle}/>
              </li>
            </ul>
            <div className="modal-footer" style={{display: isDisabled?"none":"flex"}}>
        	    <button className="btn btn-primary" onClick={saveSocialDetails}><i className="fas fa-save ml-1"></i> Save</button>
      	    </div>
	        </form>
          </div>
	        
        </div>
      </div>
      <div className="col-lg-8">
        <div className="card mb-4">
	        <div className="d-flex position-relative p-3">
	            <span><strong>Personal Details</strong></span>
	            <AddPersonalDetails/>
	            <EditPersonalDetails/>
	        </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3">
                <p className="mb-0">Name</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0">Shubhanshu Maurya</p>
              </div>
            </div>
            <hr/>
            <div className="row">
              <div className="col-sm-3">
                <p className="mb-0">Email</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0">shubhanshumaur2@gmail.com</p>
              </div>
            </div>
            <hr/>
            <div className="row">
              <div className="col-sm-3">
                <p className="mb-0">Phone</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0">(091) 8423871981</p>
              </div>
            </div>
            <hr/>
            <div className="row">
              <div className="col-sm-3">
                <p className="mb-0">Birthday</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0">6th june 1997</p>
              </div>
            </div>
            <hr/>
	          <div className="row">
              <div className="col-sm-3">
                <p className="mb-0">Age</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0">24 years</p>
              </div>
            </div>
            <hr/>
	          <div className="row">
              <div className="col-sm-3">
                <p className="mb-0">skype</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0">shubhanshumaurya</p>
              </div>
            </div>
            <hr/>
	          <div className="row">
              <div className="col-sm-3">
                <p className="mb-0">freelance</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0">available</p>
              </div>
            </div>
            <hr/>
            <div className="row">
              <div className="col-sm-3">
                <p className="mb-0">Address</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0">varanasi, uttar pradesh</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col-lg">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex p-2">
                  <span><strong>About</strong></span>
                  <div className="position-absolute" style={{right: '1rem', cursor: 'pointer'}} onClick={editAboutDetails}><i className="fa fa-edit"></i></div>
                </div>
                {/* <textarea id="myTextarea"></textarea> */}
                {/* <Editor tinymceScriptSrc="tinymce/tinymce.min.js"></Editor> */}
                <form>
                 <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    init={{
                    height: 500,
                    menubar: !isAboutDisabled,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
         
                  }}
                  style={textEditorStyle}
                  onChange = {postAboutData}
                  value = {aboutData.aboutMe}
                  name = "aboutMe"
                  disabled = {isAboutDisabled}
                 />
                 <div className="modal-footer" style={{display: isAboutDisabled?"none":"flex"}}>
        	        <button className="btn btn-primary" onClick={saveAboutDetails}><i className="fas fa-save ml-1"></i> Save</button>
      	         </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card mb-4 mb-md-0">
              <div className="card-body">
                <div className="d-flex p-2">
                  <span><strong>Awards</strong></span>
                  <div className="position-absolute" style={{right: '1rem', cursor: 'pointer'}}><i className="fa fa-edit"></i></div>
                </div>
                {/* <p className="mb-1" style={{fontSize: ".77rem"}}>Web Design</p>
                <div className="progress rounded" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "80%"}} aria-valuenow="80"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p className="mt-4 mb-1" style={{fontSize: ".77rem"}}>Website Markup</p>
                <div className="progress rounded" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "72%"}} aria-valuenow="72"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p className="mt-4 mb-1" style={{fontSize: ".77rem"}}>One Page</p>
                <div className="progress rounded" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "89%"}} aria-valuenow="89"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p className="mt-4 mb-1" style={{fontSize: ".77rem"}}>Mobile Template</p>
                <div className="progress rounded" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "55%"}} aria-valuenow="55"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p className="mt-4 mb-1" style={{fontSize: ".77rem"}}>Backend API</p>
                <div className="progress rounded mb-2" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "66%"}} aria-valuenow="66"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-4 mb-md-0">
              <div className="card-body">
                <div className="d-flex p-2">
                  <span><strong>Testimonials</strong></span>
                  <div className="position-absolute" style={{right: '1rem', pointer: 'cursor'}}><i className="fa fa-edit"></i></div>
                </div>
                {/* <p className="mb-1" style={{fontSize: ".77rem"}}>Web Design</p>
                <div className="progress rounded" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "80%"}} aria-valuenow="80"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p className="mt-4 mb-1" style={{fontSize: ".77rem"}}>Website Markup</p>
                <div className="progress rounded" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "72%"}} aria-valuenow="72"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p className="mt-4 mb-1" style={{fontSize: ".77rem"}}>One Page</p>
                <div className="progress rounded" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "89%"}} aria-valuenow="89"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p className="mt-4 mb-1" style={{fontSize: ".77rem"}}>Mobile Template</p>
                <div className="progress rounded" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "55%"}} aria-valuenow="55"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p className="mt-4 mb-1" style={{fontSize: ".77rem"}}>Backend API</p>
                <div className="progress rounded mb-2" style={{height: "5px"}}>
                  <div className="progress-bar" role="progressbar" style={{width: "66%"}} aria-valuenow="66"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
</div>
</>
)
}

export default ManageBiography;