
import {React, useRef, useState, useEffect} from 'react';
import './Alert.css';

const Alert = (props)=>{
// const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	
// 	// get windows dimensions
// 	function getWindowDimensions() {
//   		const { innerWidth: width, innerHeight: height } = window;
		
//   		return {
//     		width,
//     		height
//   		};

		
// 	}

// 	function useWindowDimensions() {

//   		useEffect(() => {
//     			function handleResize() {
//       				setWindowDimensions(getWindowDimensions());
//     			}

//     		window.addEventListener('resize', handleResize);
//     		return () => window.removeEventListener('resize', handleResize);
//   		}, []);

//   		return windowDimensions.width;
// 	}

// 	console.log(useWindowDimensions());

//dynamic alert

var successAlertShow = 'none', warningAlertShow = 'none';
const successRef = useRef(successAlertShow);
const warningRef = useRef(warningAlertShow);
var successMessage, warningMessage;

const SuccessAlert = (message)=>{

	successRef.current.style.display='block'
	warningRef.current.style.display='none';
	successMessage = message;
	setTimeout(()=>{successRef.current.style.display='none'}, 3000);
	

}

const WarningAlert = (message)=>{

	successRef.current.style.display='none'
	warningRef.current.style.display='block';
	warningMessage = message;
	setTimeout(()=>{warningRef.current.style.display='none'}, 3000);
	
}

const closeSuccessAlert = ()=>{
	successRef.current.style.display="none";
}

const closeWarningAlert = ()=>{
	warningRef.current.style.display="none";
}

if(props.alertData.status === "success"){
	SuccessAlert(props.alertData.message);
}
else if(props.alertData.status === "warning"){
	WarningAlert(props.alertData.message);
}
	return(
		<>
			<div className="alert alert-dismissible fade show m-2 w-50 success-alert"
			ref = {successRef}
			style={{display: successAlertShow, right: '2px', position: 'fixed', zIndex: '999', background: "rgba(10,102,194,.8)"}}>
				<i className="fa fa-check-circle">&nbsp;</i>{successMessage}
				<button type="button" className="btn-close" onClick={closeSuccessAlert} aria-label="close"></button>
			</div>
			<div className="alert alert-warning alert-dismissible fade show m-2 w-50 warning-alert"
			ref = {warningRef}
			style={{display: warningAlertShow, right: '2px', position: 'fixed', zIndex: '999'}}>
				<i className="fa fa-check-circle">&nbsp;</i>{warningMessage}
				<button type="button" className="btn-close" onClick={closeWarningAlert} aria-label="close"></button>
			</div>
		</>
	)
}

export default Alert;