import React from 'react';
import './Blog.css'

const Blog = () =>{
	return(
	<>
	<section id="blog" className="section">
	<div className="container">
		<div className="title">
			<h3>Latest Blog.</h3>
		</div>
		<div className="row">
			<div className="col-md-6 m-15px-tb aos-init" data-aos="fade-right"
				data-aos-duration="1200">
				<div className="blog-grid">
					<div className="blog-img">
						<img src="img/learning material.gif" alt="blog post" />
					</div>
					<div className="blog-info">
						<div className="meta">29/FEB/2020 - BY MAYANK</div>
						<h6>
							<a style={{cursor: "none"}}>Five Solid Evidences Attending
								Design Is Good For Your Career Development.</a>
						</h6>
					</div>
				</div>
			</div>
			<div className="col-md-6 m-15px-tb aos-init" data-aos="fade-right"
				data-aos-duration="1200" data-aos-delay="200">
				<div className="blog-grid">
					<div className="blog-img">
						<img src="img/2.gif" alt="blog post" />
					</div>
					<div className="blog-info">
						<div className="meta">05/JAN/2020 - BY DINESH</div>
						<h6>
							<a style={{cursor: "none"}}>Ten Mind-Blowing Reasons Why Design
								Is Using This Technique For Exposure.</a>
						</h6>
					</div>
				</div>
			</div>
			<div className="col-md-6 m-15px-tb aos-init" data-aos="fade-right"
				data-aos-duration="1200" data-aos-delay="400">
				<div className="blog-grid">
					<div className="blog-img">
						<img src="img/3.gif" alt="blog post" />
					</div>
					<div className="blog-info">
						<div className="meta">12/AUG/2020 - BY PRIYANSHU</div>
						<h6>
							<a style={{cursor: "none"}}>I Will Tell You The Truth About
								Design In The Next 60 Seconds.</a>
						</h6>
					</div>
				</div>
			</div>
			<div className="col-md-6 m-15px-tb aos-init" data-aos="fade-right"
				data-aos-duration="1200" data-aos-delay="800">
				<div className="blog-grid">
					<div className="blog-img">
						<img src="img/10.gif" alt="blog post" />
					</div>
					<div className="blog-info">
						<div className="meta">22/JUN/2020 - BY SHUBHAM</div>
						<h6>
							<a style={{cursor: "none"}}>What You Know About Design And What
								You Don't Know About Design.</a>
						</h6>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>
	</>
	);
}

export default Blog;