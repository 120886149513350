import {React, useState, useEffect} from 'react';
import SideNav from './SideNav';
import './dashboard.css';
// import ManageBiography from './HomePage/ManageBiography';


const Dashboard = ()=>{

	return(
		<>
			<div className="dashboard d-flex position-relative">
				<div>
					<SideNav></SideNav>
				</div>
				<div className="w-100">
					<MainContainer>
						
					</MainContainer>
				</div>
			</div>
			
			
		</>
	)
}



const MainContainer = () =>{
	
	
	return(
		<>
			{/* <Routes>
				<Route path="/manage-homepage" element={<ManageHomePage/>}></Route>
			</Routes> */}
		
			
		</>
	)
}

export default Dashboard;