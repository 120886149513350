import {React, useState, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Dashboard from './components/Dashboard/Dashboard';
import ManageBiography from "./components/Dashboard/HomePage/ManageBiography";
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init();

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    
    setTimeout(() => setLoading(false), 100)
  }, [])
  
  
  return (
    <>
      {loading === false ?(
        <div className="App">
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/dashboard" element={<Dashboard/>}>
            </Route>
            <Route path="/manage-homepage" element={<ManageBiography/>}></Route>
            
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </div>
        
        ):(
        
        <div className="App">
        <span className="welcome-msg fs-5"><strong>Welcome to Portfolio</strong></span>
          <header className="loader">
            <img src="img/9.png" className="loader-img"/>
            <img src="img/loader/loader.gif" className="App-logo" alt="logo" />
          </header>
        </div>
      )}
      </>
  );
}


export default App;
