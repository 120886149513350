import {React, useState} from 'react';
import './Contact.css'
import Alert from '../Alert/Alert';

const Contact = (props) =>{
	
	var Data = "";
	var showAlert= false;
	var warningShowMessage = "none";
	var successShowMessage = "none";
	//state variable to store data
	const [userData, setUserData] = useState(
		{
			fullName: "",
			email: "",
			subject: "",
			comment: ""
		}
	);
	
	let name,value;
	const postUserData = (event)=>{
		name = event.target.name;
		value = event.target.value;
		setUserData({...userData, [name]: value});
	}

	//connect with firebase and store data (in userDataRecord table in json format)
	const submitData = async (event)=>{
		event.preventDefault();
		const {fullName, email, subject, comment} = userData;

		//check if all data is filled
		if(fullName && email && subject && comment){

		
		//fetch database using api
		const res = fetch("https://sbkm-personal-profile-default-rtdb.asia-southeast1.firebasedatabase.app/userDataRecords.json",
		{
		method : "POST",
		headers: {
			"Content-Type" : "application/json"
		},
		body:JSON.stringify({
			fullName, email, subject, comment
		})
	});

	if(res){
		Data = "Message Sent";
		successShowMessage = "success";
		//showAlert = true;
		props.sendData(Data,successShowMessage);
		setUserData({
			fullName: "",
			email: "",
			subject: "",
			comment: ""
		})
	}else{
		Data = "please fill the data";
		warningShowMessage = "warning";
		//showAlert = true;
		props.sendData(Data,warningShowMessage);
	}
	}
	else{
		Data = "please fill the all data";
		warningShowMessage = "warning";
		//showAlert = true;
		props.sendData(Data,warningShowMessage);
	}
}
	return(
	<>
	<section id="contact" className="section theme-light dark-bg">
	<div className="container">
		<div className="row">
			<div className="col-lg-5 col-xl-4 m-15px-tb aos-init aos-animate"
				data-aos="fade-right" data-aos-duration="1200">
				<div className="contact-info">
					<h4>What’s your story? Get in touch</h4>
					<p>Always available for freelancing if the right project comes
						along, Feel free to contact me.</p>
					<ul>
						<li className="media"><i className="fa-solid fa-map-location-dot"></i><span
							className="media-body">varanasi, Uttar Pradesh</span></li>
						<li className="media"><i className="fa-solid fa-at"></i><span
							className="media-body">shubhanshumaur2@gmail.com</span></li>
						<li className="media"><i className="fa-solid fa-mobile-screen"></i><span
							className="media-body">+91 9140744685</span></li>
					</ul>
				</div>
			</div>
			<div className="col-lg-7 ml-auto m-15px-tb aos-init aos-animate"
				data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
				<div className="contact-form">
					<h4>Say Something</h4>
					<form method="POST">
						<div className="row">
							<div className="col-md-6">
								<div className="form-group mb-3">
									<input type="text" className="form-control theme-light"
										value={userData.fullName} onChange={postUserData} placeholder="Full name" name="fullName" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group mb-3">
									<input type="email" className="form-control theme-light"
										value={userData.email} onChange={postUserData} placeholder="Email address" name="email" />
								</div>
							</div>
							<div className="col-12">
								<div className="form-group mb-3">
									<input type="text" className="form-control theme-light"
										value={userData.subject} onChange={postUserData} placeholder="Subject" name="subject" />
								</div>
							</div>
							<div className="col-12">
								<div className="form-group mb-3">
									<textarea rows="4" className="form-control theme-light"
										value={userData.comment} onChange={postUserData} placeholder="Type comment" name="comment"></textarea>
								</div>
							</div>
							<div className="col-12">
								<div className="btn-bar">
									<button className="px-btn px-btn-white" onClick={submitData} style={{cursor: "none"}}>Send
										Message</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className="col-12 aos-init" data-aos="fade-up"
				data-aos-duration="1200" data-aos-delay="300">
				<div className="google-map">
					<div className="embed-responsive embed-responsive-21by9">
						<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d115408.09194075856!2d82.92106781328533!3d25.32090127603645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1657705820843!5m2!1sen!2sin" width="800" height="600" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>	
	</>
	);
}

export default Contact;