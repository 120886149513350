import React from 'react';
import './Work.css'

const Work = () =>{
	return(
	<>
		<section id="work" className="section theme-light dark-bg">
	<div className="container">
		<div className="title">
			<h3>My Portfolio.</h3>
		</div>
		<div className="portfolio-filter-01">
			<div className="react-tabs" data-rttabs="true">
				<ul className="filter d-flex flex-wrap justify-content-start"
					role="tablist">
					<li className="react-tabs__tab react-tabs__tab--selected" role="tab"
						id="react-tabs-0" aria-selected="true" aria-disabled="false"
						aria-controls="react-tabs-1" tabIndex="0" data-rttab="true">All</li>
					{/* <li className="react-tabs__tab" role="tab" id="react-tabs-2"
						aria-selected="false" aria-disabled="false"
						aria-controls="react-tabs-3" data-rttab="true">Branding</li>
					<li className="react-tabs__tab" role="tab" id="react-tabs-4"
						aria-selected="false" aria-disabled="false"
						aria-controls="react-tabs-5" data-rttab="true">Photography</li>
					<li className="react-tabs__tab" role="tab" id="react-tabs-6"
						aria-selected="false" aria-disabled="false"
						aria-controls="react-tabs-7" data-rttab="true">Fashion</li>
					<li className="react-tabs__tab" role="tab" id="react-tabs-8"
						aria-selected="false" aria-disabled="false"
						aria-controls="react-tabs-9" data-rttab="true">Product</li> */}
				</ul>
				<div>
					<div className="react-tabs__tab-panel react-tabs__tab-panel--selected"
						role="tabpanel" id="react-tabs-1" aria-labelledby="react-tabs-0">
						<div className="portfolio-content ">
							<div className="my-masonry-grid">
								<div className="my-masonry-grid_column" style={{width: "33.3333%"}}>
									<div className="portfolio-box-01 aos-init" data-aos="fade-right"
										data-aos-duration="1200" data-aos-delay="">
										<div className="portfolio-img">
											<div className="portfolio-info">
												<h5>
													<a
														href="#work"
														target="_blank" rel="noreferrer" style={{cursor: "none"}}>Bottle
														Illustration</a>
												</h5>
												<span>Figma Shoot</span>
											</div>
											<a href="img/1.gif"
												className="gallery-link" style={{cursor: "none"}}><img
												src="img/1.gif"
												alt="Bottle Illustration" srl_elementid="0" /></a><a
												className="portfolio-icon"
												href="#work"
												target="_blank" rel="noreferrer" style={{cursor: "none"}}><svg
													stroke="currentColor" fill="none" strokeWidth="2"
													viewBox="0 0 24 24" strokeLinecap="round"
													strokeLinejoin="round" height="1em" width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
													<path
														d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></a>
										</div>
									</div>
									<div className="portfolio-box-01 aos-init" data-aos="fade-right"
										data-aos-duration="1200" data-aos-delay="0">
										<div className="portfolio-img">
											<div className="portfolio-info">
												<h5>
													<a
														href="#work"
														target="_blank" rel="noreferrer" style={{cursor: "none"}}>Business
														Card</a>
												</h5>
												<span>Graphicriver Market</span>
											</div>
											<a href="img/2.gif"
												className="gallery-link" style={{cursor: "none"}}><img
												src="img/2.gif" alt="E-Cosmetics"
												srl_elementid="1" /></a><a className="portfolio-icon"
												href="#work"
												target="_blank" rel="noreferrer" style={{cursor: "none"}}><svg
													stroke="currentColor" fill="none" strokeWidth="2"
													viewBox="0 0 24 24" strokeLinecap="round"
													strokeLinejoin="round" height="1em" width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
													<path
														d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></a>
										</div>
									</div>
									{/* <div className="portfolio-box-01 aos-init" data-aos="fade-right"
										data-aos-duration="1200" data-aos-delay="0">
										<div className="portfolio-img">
											<div className="portfolio-info">
												<h5>
													<a
														href="https://dribbble.com/shots/16529282-Shane-Personal-Portfolio-React-Template"
														target="_blank" rel="noreferrer" style={{cursor: "none"}}>Business
														Card</a>
												</h5>
												<span>Graphicriver Market</span>
											</div>
											<a href="img/portfolio/m-portfolio-7.jpg"
												className="gallery-link" style={{cursor: "none"}}><img
												src="img/me1.jpg" alt="Business Card"
												srl_elementid="2" /></a><a className="portfolio-icon"
												href="https://dribbble.com/shots/16529282-Shane-Personal-Portfolio-React-Template"
												target="_blank" rel="noreferrer" style={{cursor: "none"}}><svg
													stroke="currentColor" fill="none" strokeWidth="2"
													viewBox="0 0 24 24" strokeLinecap="round"
													strokeLinejoin="round" height="1em" width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
													<path
														d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></a>
										</div>
									</div> */}
								</div>
								<div className="my-masonry-grid_column" style={{width: "33.3333%"}}>
									<div className="portfolio-box-01 aos-init" data-aos="fade-right"
										data-aos-duration="1200" data-aos-delay="100">
										<div className="portfolio-img">
											<div className="portfolio-info">
												<h5>
													<a
														href="#work"
														target="_blank" rel="noreferrer" style={{cursor: "none"}}>E-Learning
														App</a>
												</h5>
												<span>Nuna ios App</span>
											</div>
											<a href="img/3.gif"
												className="gallery-link" style={{cursor: "none"}}><img
												src="img/3.gif" alt="Illustration"
												srl_elementid="3" /></a><a className="portfolio-icon"
												href="#work"
												target="_blank" rel="noreferrer" style={{cursor: "none"}}><svg
													stroke="currentColor" fill="none" strokeWidth="2"
													viewBox="0 0 24 24" strokeLinecap="round"
													strokeLinejoin="round" height="1em" width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
													<path
														d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></a>
										</div>
									</div>
									<div className="portfolio-box-01 aos-init" data-aos="fade-right"
										data-aos-duration="1200" data-aos-delay="100">
										<div className="portfolio-img">
											<div className="portfolio-info">
												<h5>
													<a
														href="#work"
														target="_blank" rel="noreferrer" style={{cursor: "none"}}>Chatting
														Application</a>
												</h5>
												<span>Codecanyon Market</span>
											</div>
											<a href="img/10.gif"
												className="gallery-link" style={{cursor: "none"}}><img
												src="img/10.gif"
												alt="Bottle Illustration" srl_elementid="4" /></a><a
												className="portfolio-icon"
												href="#work"
												target="_blank" rel="noreferrer" style={{cursor: "none"}}><svg
													stroke="currentColor" fill="none" strokeWidth="2"
													viewBox="0 0 24 24" strokeLinecap="round"
													strokeLinejoin="round" height="1em" width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
													<path
														d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></a>
										</div>
									</div>
									{/* <div className="portfolio-box-01 aos-init" data-aos="fade-right"
										data-aos-duration="1200" data-aos-delay="100">
										<div className="portfolio-img">
											<div className="portfolio-info">
												<h5>
													<a
														href="https://www.behance.net/gallery/128278341/Imroz-Vue-JS-Creative-Agency-Portfolio-Template"
														target="_blank" rel="noreferrer" style={{cursor: "none"}}>Chatting
														App Design</a>
												</h5>
												<span>Codecanyon Market</span>
											</div>
											<a href="img/portfolio/m-portfolio-8.jpg"
												className="gallery-link" style={{cursor: "none"}}><img
												src="img/me1.jpg"
												alt="Chatting App Design" srl_elementid="5" /></a><a
												className="portfolio-icon"
												href="https://www.behance.net/gallery/128278341/Imroz-Vue-JS-Creative-Agency-Portfolio-Template"
												target="_blank" rel="noreferrer" style={{cursor: "none"}}><svg
													stroke="currentColor" fill="none" strokeWidth="2"
													viewBox="0 0 24 24" strokeLinecap="round"
													strokeLinejoin="round" height="1em" width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
													<path
														d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></a>
										</div>
									</div> */}
								</div>
								<div className="my-masonry-grid_column" style={{width: "33.3333%"}}>
									<div className="portfolio-box-01 aos-init" data-aos="fade-right"
										data-aos-duration="1200" data-aos-delay="200">
										<div className="portfolio-img">
											<div className="portfolio-info">
												<h5>
													<a
														href="#work"
														target="_blank" rel="noreferrer" style={{cursor: "none"}}>Visual
														Design</a>
												</h5>
												<span>Themeforest Marke</span>
											</div>
											<a href="img/11.gif"
												className="gallery-link" style={{cursor: "none"}}><img
												src="img/11.gif" alt="Business Mockup"
												srl_elementid="6" /></a><a className="portfolio-icon"
												href="#work"
												target="_blank" rel="noreferrer" style={{cursor: "none"}}><svg
													stroke="currentColor" fill="none" strokeWidth="2"
													viewBox="0 0 24 24" strokeLinecap="round"
													strokeLinejoin="round" height="1em" width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
													<path
														d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></a>
										</div>
									</div>
									<div className="portfolio-box-01 aos-init" data-aos="fade-right"
										data-aos-duration="1200" data-aos-delay="200">
										<div className="portfolio-img">
											<div className="portfolio-info">
												<h5>
													<a
														href="#work"
														target="_blank" rel="noreferrer" style={{cursor: "none"}}>Web
														Application</a>
												</h5>
												<span>Behance Shot</span>
											</div>
											<a href="img/7.gif"
												className="gallery-link" style={{cursor: "none"}}><img
												src="img/7.gif" alt="Web Application"
												srl_elementid="7" /></a><a className="portfolio-icon"
												href="#work"
												target="_blank" rel="noreferrer" style={{cursor: "none"}}><svg
													stroke="currentColor" fill="none" strokeWidth="2"
													viewBox="0 0 24 24" strokeLinecap="round"
													strokeLinejoin="round" height="1em" width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
													<path
														d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></a>
										</div>
									</div>
									{/* <div className="portfolio-box-01 aos-init" data-aos="fade-right"
										data-aos-duration="1200" data-aos-delay="200">
										<div className="portfolio-img">
											<div className="portfolio-info">
												<h5>
													<a href="https://www.facebook.com/ibthemes" target="_blank"
														rel="noreferrer" style={{cursor: "none"}}>Web Motion</a>
												</h5>
												<span>Behance Shot</span>
											</div>
											<a href="img/portfolio/m-portfolio-9.jpg"
												className="gallery-link" style={{cursor: "none"}}><img
												src="img/me1.jpg" alt="Web Motion"
												srl_elementid="8" /></a><a className="portfolio-icon"
												href="https://www.facebook.com/ibthemes" target="_blank"
												rel="noreferrer" style={{cursor: "none"}}><svg
													stroke="currentColor" fill="none" strokeWidth="2"
													viewBox="0 0 24 24" strokeLinecap="round"
													strokeLinejoin="round" height="1em" width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
													<path
														d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></a>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					{/* <div className="react-tabs__tab-panel" role="tabpanel"
						id="react-tabs-3" aria-labelledby="react-tabs-2"></div>
					<div className="react-tabs__tab-panel" role="tabpanel"
						id="react-tabs-5" aria-labelledby="react-tabs-4"></div>
					<div className="react-tabs__tab-panel" role="tabpanel"
						id="react-tabs-7" aria-labelledby="react-tabs-6"></div>
					<div className="react-tabs__tab-panel" role="tabpanel"
						id="react-tabs-9" aria-labelledby="react-tabs-8"></div> */}
				</div>
			</div>
		</div>
	</div>
</section>
	</>
	);
}

export default Work;