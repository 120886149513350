import React from 'react';
import './Resume.css'

const Resume = () =>{
	return(
	<>
		<section id="resume" className="section">
			<div className="container">
				<div className="title">
					<h3>Experience.</h3>
				</div>
							<div className="resume-box">
								{/* <div className="resume-row aos-init" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="">
									<div className="row">
									<div className="col-md-4 col-xl-3">
										<div className="rb-left">
											<h6>Sr. Front-end Engineer</h6>
											<label>Google | Remote</label>
											<p>Feb 2021 - Present</p>
											<div className="rb-time">Full Time</div>
										</div>
									</div>
									<div className="col-md-8 col-xl-9">
										<div className="rb-right">
											<h6>Google LLC</h6>
											<p>
											</p>
										</div>
									</div>
									</div>
								</div> */}
								<div className="resume-row aos-init" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
									<div className="row">
										<div className="col-md-4 col-xl-3">
											<div className="rb-left">
												<h6>Assosiate Engineer</h6>
												<label>Nagarro | Apprenticeship</label>
												<p>Mar 2022 - Present</p>
												<div className="rb-time">Full Time</div>
											</div>
										</div>
										<div className="col-md-8 col-xl-9">
											<div className="rb-right">
												<h6>Nagarro <label>IT Service Management Company</label></h6>
												<p>
												Nagarro SE is a digital engineering company. It offers application development and management, 
												digital product engineering, artificial intelligence and machine learning, cloud solutions, 
												digital marketing, IoT solutions, digital commerce solutions, managed services, and others. 
												The company's geographical segment includes North America, Central Europe, Rest of Europe, and Rest of World. 
												It derives a majority of its revenue from the North America segment.
												</p>
											</div>
										</div>
									</div>
								</div>
    								<div className="resume-row aos-init" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
									<div className="row">
										<div className="col-md-4 col-xl-3">
											<div className="rb-left">
												<h6>Cyber Security</h6>
												<label>TalaKunchi Networks Pvt Ltd. | Remote Internship</label>
												<p>Nov 2021 - Mar 2022</p>
												<div className="rb-time">Full Time</div>
											</div>
										</div>
										<div className="col-md-8 col-xl-9">
											<div className="rb-right">
												<h6>TalaKunchi Networks Pvt Ltd</h6>
												<p> 
												It is an information security company with highly trained resources and skills of information security services,
												solution deployment and training. The team provides support at each given stage from Ideation till Go-Live: ensuring the confidentiality of customer's data, 
												availability of customer's environment and keeping integrity and trustworthiness of customer's data.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
    				<div className="separated" style={{backgroundImage: "url(img/me1.png)"}}></div>
				<div className="title">
					<h3>Education &amp; Skills</h3> 
				</div>
				<div className="row align-items-center">
					<div className="col-lg-4 m-15px-tb aos-init" data-aos="fade-up" data-aos-duration="1200">
						<ul className="aducation-box">
							<li>
								<span>2020-2022</span>
								<h6>Master of Computer Application </h6>
								<p>Harcourt Butler Technical University</p> 
							</li>
							<li>
								<span>2016-2019</span>
								<h6>Bachelor of Computer Application </h6>
								<p>School Of Management Sciences</p> 
							</li>
							<li>
								<span>2014-2015</span>
								<h6>Intermediate  </h6>
								<p>Oasis Public School</p> 
							</li>
						</ul>
					</div>
					<div className="col-lg-7 ml-auto m-15px-tb aos-init" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
						<div className="skill-wrapper">
							<div className="skill-lt">
								<h6>HTML5</h6>
								<span className="count-inner"><span>92</span>%</span>
								<div className="skill-bar">
									<div className="skill-bar-in" style={{width: "92%"}}></div>
								</div>
							</div>
							<div className="skill-lt">
								<h6>React JS</h6>
								<span className="count-inner"><span>65</span>%</span>
								<div className="skill-bar">
									<div className="skill-bar-in" style={{width: "65%"}}></div>
								</div>
							</div>
							<div className="skill-lt">
								<h6>Angular</h6>
								<span className="count-inner"><span>70</span>%</span>
								<div className="skill-bar">
									<div className="skill-bar-in" style={{width: "70%"}}></div>
								</div>
							</div>
							<div className="skill-lt">
								<h6>Ui/Ux</h6>
								<span className="count-inner"><span>88</span>%</span>
								<div className="skill-bar">
									<div className="skill-bar-in" style={{width: "88%"}}></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>	
	</>
	);
}

export default Resume;