import React from 'react';

const AddPersonalDetails = () => {
	return (
		<>
			<div className="modal fade" id="modalAddForm" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
				aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header text-center">
							<h4 className="modal-title w-100 font-weight-bold">Add New Details</h4>
							<button type="button" className="btn close shadow-sm" data-bs-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body mx-3">
							<div className="form-floating mb-5">
								<input type="text" id="form34" className="form-control validate" />
								<label data-error="wrong" data-success="right" htmlFor="form34"><i className="fas fa-user"></i> Name</label>
							</div>

							<div className="form-floating mb-5">
								<input type="email" id="form29" className="form-control validate" />
								<label data-error="wrong" data-success="right" htmlFor="form29"><i className="fas fa-envelope"></i> Email</label>
							</div>

							<div className="form-floating mb-5">
								<input type="tel" id="form29" className="form-control validate" />
								<label data-error="wrong" data-success="right" htmlFor="form29"><i className="fas fa-mobile"></i> Phone Number</label>
							</div>

							<div className="row g-3">
								<div className="col form-floating mb-5">
									<input type="date" id="form29" className="form-control validate" />
									<label data-error="wrong" data-success="right" htmlFor="form29"><i className="fas fa-birthday-cake"></i> Birthday</label>
								</div>
								<div className="col form-floating mb-5">
									<input type="text" id="form29" className="form-control validate" />
									<label data-error="wrong" data-success="right" htmlFor="form29"><i className="fas fa-child"></i> Age</label>
								</div>
							</div>

							<div className="form-floating mb-5">
								<input type="text" id="form32" className="form-control validate" />
								<label data-error="wrong" data-success="right" htmlFor="form32"><i className="fa fa-skype"></i> Skype</label>
							</div>

							<div className="form-floating form-group mb-5">
								<textarea type="text" id="form8" className="form-control" rows="6"></textarea>
								<label data-error="wrong" data-success="right" htmlFor="form8"><i className="fas fa-address-card"></i> Address</label>
							</div>

							<div className="form-group">
								<label className="form-label">Are you available for free-lancing ? &nbsp;</label>
								<div className="pt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="available" />
										<label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
									</div>
									<div className="form-check form-check-inline">
										<input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="not-available" />
										<label className="form-check-label" htmlFor="inlineRadio2">No</label>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer d-flex">
							<button className="btn btn-primary"><i className="fas fa-save ml-1"></i> Save</button>
						</div>
					</div>
				</div>
			</div>

			{/* add button */}
			<div className="position-absolute btn mb-4" style={{ right: "3rem", cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#modalAddForm"><i className="fa fa-add"></i></div>
		</>
	)
}

export default AddPersonalDetails;