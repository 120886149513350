import {React, useState} from 'react';
import './HomePage.css';

const HomePage = (props) => {
	const [isDark, setIsDark] = useState(false);
	const DarkMode = (event) => {
		document.body.classList.remove('theme-light');
  		document.body.classList.add('theme-dark');
		//props.sendData(true);
		//isDark = true;
		setIsDark(true);
		
	}
	const LightMode = (event) => {
  		document.body.classList.remove('theme-dark');
		document.body.classList.add('theme-light');
		//props.sendData(false);
		//isDark =false;
		setIsDark(false);
	}

	return(
		<>
			<section className="home-banner" id="home">
				<div className="hb-top-fixed d-flex">
					<div className="hb-info">
						<a href="tel:+91 8423871981" style={{}}>+91 8423871981</a>
						<a href="mailto:mail%20to:shubhanshumaur2@gmail.com,com" style={{}}> shubhanshumaur2@gmail.com</a>
					</div>
					<div className="hb-lang">
						<ul className="nav">
							<li className="active" style={{cursor: "none",backgroundColor:"transparent", display : isDark?"none":"block"}}>
								<a href="#" onClick={DarkMode} style={{cursor: "none",color: isDark?"white":"#13294A"}}><i className="fa-solid fa-moon fs-5"></i></a>
							</li>
							<li style={{display : isDark?"block":"none", backgroundColor:"transparent"}}>
								<a href="#" onClick={LightMode} style={{cursor: "none",color: isDark?"white":"#13294A"}}><i className="fa-solid fa-sun fs-5"></i></a>
							</li>
						</ul>
					</div>
				</div>
				

				<div className="container">
					<div className="row full-screen align-items-center">
						<div className="col-lg-7">
							<div className="type-box ">
								<h6 data-aos="fade-up" data-aos-duration="1200" className="aos-init aos-animate ">Hello, My name is</h6>
								<h1 className="font-alt aos-init aos-animate " data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">Shubhanshu Maurya</h1>
								
								<div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" className="aos-init aos-animate">
									<div className="x0 x1 x2 ">
										<div style={{transition: "width 150ms linear 0s", height: "45px", width: "210.234px"}}>
											<div className="x0 x3 x4 x5" style={{opacity: "0.000199035", transform: "translateY(-44.9908px)", position: "absolute"}}>
												<p className="loop-text lead text-dark"> UI/UX Designer</p>
											</div>
											
											<div className="x0 x3 x4 x5" style={{opacity: "0.999801", transform: "translateY(0.00924562px)", position: "absolute"}}>
												<p className="loop-text lead"> App Developer</p>
											</div>
										</div>
									</div>
								</div>
								<p className="desc aos-init" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">I design and develop services for customers of all sizes,
  									specializing in creating stylish, modern websites, web
  									services and online stores.
								</p>
								<div className="mt-4 aos-init" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
									<a className="px-btn px-btn-white" href="documents/shubhanshu's CV.pdf" download style={{cursor: "none"}}> Donwload CV</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="hb-me" style={{backgroundImage: "url(img/me5.png)"}}></div>
			</section>
		</>
	);
}

export default HomePage;